<template>
  <router-view v-if="['LbStat'].includes($route.name)" />
  <PageHeaderLayout class="main-page-content-nav" v-else>
    <ApeMenu :menus='menus' />
  </PageHeaderLayout>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import myNav from '@/json/menus.json'
// import ApeMenu from '@/components/ApeMenu'

export default {
  name: 'Index',
  components: { PageHeaderLayout },
  data() {
    return {
      showHeader: false,
      menus: []
      // menus: [
      //   {
      //     icon: 'paiqi',
      //     display_name: '选品日期',
      //     url: '/lb_menu/lb_kol',
      //     permission_name: 'lb_kol',
      //     meta: { title: '红人选品', showMenu: false }
      //   },
      //   {
      //     icon: 'paiqi',
      //     display_name: '直播日期',
      //     url: '/lb_menu/lb_live',
      //     permission_name: 'lb_live',
      //     meta: { title: '直播选品', showMenu: false }
      //   },
      //   // {
      //   //   icon: 'paiqi',
      //   //   display_name: '直播排期',
      //   //   url: '/lb_menu/lb_schedule',
      //   //   permission_name: 'lb_schedule_list',
      //   //   meta: { title: '直播排期', showMenu: false }
      //   // },
      //
      //   {
      //     icon: 'chanpin',
      //     display_name: '产品库',
      //     url: '/lb_menu/product_library',
      //     permission_name: 'product_list'
      //   },
      //   {
      //     icon: 'cp-shenhe',
      //     display_name: '产品库审核',
      //     url: '/lb_menu/product_cosmetic_audit',
      //     permission_name: 'live-product-list'
      //   },
      //   {
      //     icon: 'cp-shenhe',
      //     display_name: '服饰库审核',
      //     url: '/lb_menu/product_dress_audit',
      //     permission_name: 'live-product-list'
      //   },
      //
      //   {
      //     'icon': 'file-conversion-one',
      //     'display_name': '品牌库',
      //     'url': '/lb_menu/product_brand',
      //     'permission_name': 'brand_hub_brand'
      //   },
      //   {
      //     'icon': 'yilan',
      //     'display_name': '服饰品牌库',
      //     'url': '/lb_menu/product_dress_brand',
      //     'permission_name': 'dress_brand_list'
      //   },
      //   {
      //     'icon': 'yilan',
      //     'display_name': '服饰产品库',
      //     'url': '/lb_menu/product_dress',
      //     'permission_name': 'product_dress_list'
      //   },
      //   {
      //     'icon': 'yilan',
      //     'display_name': '寄样管理',
      //     'url': '/lb_menu/product_specimen_list',
      //     'permission_name': 'product_specimen_view'
      //   },
      //   {
      //     icon: 'yilan',
      //     display_name: '招商情况一览',
      //     url: '/lb_menu/product_investment',
      //     permission_name: 'live-product-view'
      //   },
      //   {
      //     icon: 'yilan',
      //     display_name: '运营情况一览',
      //     url: '/lb_menu/opt_view',
      //     permission_name: 'live-product-opt-page'
      //   }
      //   // {
      //   //   icon: 'iconRectangleCopy1',
      //   //   display_name: '直播选品',
      //   //   url: '/lb_menu/product_selection',
      //   //   permission_name: '',
      //   //   disabled: true
      //   // },
      // ]
    }
  },
  methods: {
    getMenus() {
      //统一菜单配置读取，避免调整时不同步
      let pageMenus = myNav.find(value => value.url === '/lb_menu')

      return pageMenus || []
    }
  },
  created() {
    let menuObj = this.getMenus()
    this.menus = menuObj.children || []
    // console.log(this.menus)
  }
}
</script>

<style scoped>
</style>
